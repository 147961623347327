<template>
  <div class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative">
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div
      class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl mt-20 md:mt-4"
    >
      <div v-if="showContentOnMobile">
        <div class="viewer" data-page="1">
          <div
            class="container"
            tabindex="0"
            style="
              user-select: none;
              -webkit-user-drag: none;
              touch-action: none;
            "
          >
            <canvas
              class="view360-canvas"
              width="800"
              height="480"
              style="
                inset: 0px;
                margin: auto;
                max-height: 100%;
                max-width: 100%;
                outline: none;
                position: absolute;
              "
            ></canvas>
          </div>
          <div class="move-exp-message">
            <div class="w-full flex justify-center items-center">
              <div>
                <lottie-animation
                  path="lotties/move-phone.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="0.5"
                  :width="50"
                  :height="50"
                />
              </div>
              <div>Move your phone around to experience. {{ device }}</div>
            </div>
          </div>
          <div class="image360_loading">
            <div class="image360_loadingBar"></div>
          </div>
          <div class="panoviewer-control">
            <button class="enterfs" @click="toggle">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    d="M30,5 L5,5 L5,30"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M70,5 L95,5 L95,30"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M30,95 L5,95 L5,65"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M70,95 L95,95 L95,65"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </g>
              </svg>
            </button>
            <button class="exitfs" @click="toggle">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    d="M30,5 L30,30 L5,30"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M70,5 L70,30 L95,30"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M30,95 L30,65 L5,65"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M70,95 L70,65 L95,65"
                    fill="transparent"
                    stroke="#fff"
                    stroke-width="8"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div class="fullscreen-container" style="display: none"></div>
      </div>
      <div
        v-else
        class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl px-6 py-10"
      >
        <div class="w-full grid gap-10">
          <p class="text-lg text-black text-center font-bold">
            Rotate your phone
          </p>
          <div class="place-self-center">
            <lottie-animation
              path="lotties/rotate-portrait.json"
              :loop="true"
              :autoPlay="true"
              :speed="0.5"
              :width="200"
              :height="200"
            />
          </div>
          <p class="text-sm text-black text-center">
            Please use potrait mode for this experience
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <ExpFeedback :exp="obkSlugs.welcomeKitAR" />
    </div>
    <Footer />
  </div>
</template>

<script>
import ExpFeedback from "@/components/Demo/ExpFeedback.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import obkSlugs from "@/common/slugs";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";
import { PanoViewer } from "@egjs/view360";
import { isIOS } from "mobile-device-detect";
import Swal from "sweetalert2";
import { ref, defineComponent, toRefs, reactive } from "vue";
import { api as fullscreen } from "vue-fullscreen";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default defineComponent({
  components: {
    ExpFeedback,
    MobileHeader,
    Heading,
    Footer,
    LottieAnimation,
  },
  created() {},
  data() {
    return {
      isIOS: isIOS,
      viewer: null,
      showContentOnMobile: true,
      device: null,
      obkSlugs,
    };
  },
  setup() {
    const root = ref();
    const state = reactive({
      fullscreen: false,
      teleport: true,
    });

    async function toggle() {
      var target = document.querySelector(".viewer");
      var originalContainer = target.parentElement;
      var nextElementSibling = target.nextElementSibling;
      var fullscreenContainer = document.getElementsByClassName(
        "fullscreen-container"
      )[0];
      await fullscreen.toggle(
        document.querySelector(".fullscreen-container")[0],
        {
          teleport: state.teleport,
          callback: (isFullscreen) => {
            console.log(isFullscreen);
            var rootNode = target.parentNode.removeChild(target);
            var requestFullscreen;

            if (isFullscreen) {
              requestFullscreen = isFullscreen;
            } else {
              requestFullscreen === "full";
            }

            if (requestFullscreen) {
              document.body.style.overflow = "hidden";
              fullscreenContainer.appendChild(rootNode);
              fullscreenContainer.style.display = "block";
            } else {
              document.body.style.overflow = "initial";
              originalContainer.insertBefore(rootNode, nextElementSibling);
              fullscreenContainer.style.display = "none";
            }
            this.viewer.updateViewportDimensions();
          },
        }
      );
      state.fullscreen = fullscreen.isFullscreen;
    }

    return {
      root,
      ...toRefs(state),
      toggle,
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // var currentOrientation = window.screen.orientation.type;
      var currentOrientation =
        (screen.orientation || {}).type ||
        screen.mozOrientation ||
        screen.msOrientation;
      if (
        currentOrientation === "landscape-primary" ||
        currentOrientation === "landscape-secondary"
      ) {
        this.showContentOnMobile = false;
      } else {
        this.setupExperience();
      }
      let landscape = window.matchMedia("(orientation: landscape)");
      var self = this;
      landscape.addEventListener("change", function (e) {
        if (e.matches) {
          self.showContentOnMobile = false;
        } else {
          self.showContentOnMobile = true;
          location.reload();
        }
      });
    } else {
      this.setupExperience();
    }
    // "https://texperia-testing.s3.ap-south-1.amazonaws.com/test/welcomear/image.jpg"
  },
  methods: {
    getHeaderText() {
      return "Welcome 360";
    },
    setupExperience() {
      var self = this;
      this.showLoading(true);
      var container = document.querySelector(".viewer .container");
      var viewer = new PanoViewer(container, {
        image:
          "https://texperia-testing.s3.ap-south-1.amazonaws.com/test/welcomear/image.jpg",
        useZoom: false,
        gyroMode: "yawPitch",
        projectionType: "cubemap",
        cubemapConfig: {
          tileConfig: { flipHorizontal: true, rotation: 0 },
        },
      })
        .on("ready", function () {
          viewer.lookAt({
            fov: 80,
          });

          setTimeout(function () {
            viewer.lookAt(
              {
                fov: 65,
              },
              500
            );
            self.showLoading(false);
          });
        })
        .on("error", function (e) {
          console.error(e);
        });
      if (this.isIOS) {
        viewer.enableSensor().catch(() => {
          Swal.fire({
            title: "Device motion & Orientation Permission needed!",
            icon: "question",
            text: "For the best experience, please allow permissions to access Device motion & Orientation",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Allow",
            cancelButtonText: "Deny",
          }).then((result) => {
            if (result.value) {
              // Granted
              viewer
                .enableSensor()
                .then(() => {
                  Swal.fire("Thank you!", "", "success");
                })
                .catch(() => {
                  Swal.fire(
                    "You've denied a permission!",
                    "You have to completely close out your browser and reconnect this page to enable sensor again!",
                    "error"
                  );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("AWW :(", "", "error");
            }
          });
        });
      }
      this.viewer = viewer;
      // var self = this;
      // this.showLoading(true);
      // var container = document.querySelector(".viewer .container");
      // var viewer = new PanoViewer(container, {
      //   image:
      //     "https://texperia-testing.s3.ap-south-1.amazonaws.com/test/welcomear/image.jpg",
      //   useZoom: false,
      //   gyroMode: "yawPitch",
      //   projectionType: "cubemap",
      //   cubemapConfig: {
      //     tileConfig: { flipHorizontal: true, rotation: 0 },
      //   },
      // })
      //   .on("ready", function () {
      //     viewer.lookAt({
      //       fov: 80,
      //     });

      //     setTimeout(function () {
      //       viewer.lookAt(
      //         {
      //           fov: 65,
      //         },
      //         500
      //       );
      //       self.showLoading(false);
      //     });
      //   })
      //   .on("error", function (e) {
      //     console.error(e);
      //   });
      // this.viewer = viewer;
    },
    checkIfGyroIsEnabled() {
      if (this.isIOS) {
        PanoViewer.isGyroSensorAvailable((available) => {
          if (!available) {
            Swal.fire({
              title: "Please enable the Motion Sensor! (iOS12.2~13)",
              icon: "warning",
              text: "This website requires a permission for your device sensor.",
              html: `
                <div style="text-align: left;">
                    <div>
                    1. Open <img src="https://developer.apple.com/design/human-interface-guidelines/ios/images/icons/app_icons/Settings_2x.png" width="20" /> <b>Settings</b>
                    </div>
                    <div>
                    2. Select <img src="https://km.support.apple.com/resources/sites/APPLE/content/live/IMAGES/0/IM26/en_US/safari-240.png" width="20" /> <b>Safari</b>
                    </div>
                    <div>
                    3. Enable <b>Motion & Orientation Access</b>
                    </div>
                    <div>4. Reload the page</div>
                </div>
                `,
            });
          }
        });
      }
    },
    showLoading(isVisible) {
      var loadingEl = document.querySelector(".image360_loading");

      if (!loadingEl) {
        console.warn("loading layer does not exist.");
        return;
      }
      var visible = isVisible == undefined || isVisible == true ? true : false;
      var loadingClassList = loadingEl.classList;

      if (visible) {
        loadingClassList.add("is-loading");
      } else {
        loadingClassList.remove("is-loading");
      }
    },
  },
});
</script>
<style scoped>
.viewer {
  position: relative;
  width: 100%;
  /* height: 400px; */
  max-width: 800px;
  margin: 0px auto;
  overflow: hidden;
  outline: none;
  background: rgba(0, 0, 0, 0.7);
}

.fullscreen-container .viewer {
  max-width: none;
  height: 100%;
}

.viewer:after {
  position: relative;
  display: block;
  content: "";
  padding-top: 60%;
  width: 100%;
  z-index: -1;
}

.viewer .container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.viewer canvas {
  width: 100%;
  height: 100%;
}

.viewer video {
  position: absolute;
  visibility: hidden;
}

.infos {
  display: inline-block;
  text-align: left;
}

p.info .head {
  display: inline-block;
  padding-bottom: 5px;
  font-weight: bold;
}

p.info .value {
  vertical-align: middle;
  font-weight: 300;
  background: #eee;
  padding: 0px 6px;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
}

.button.light {
  border: 1px solid #ddd;
  margin: 5px;
  padding: 10px;
  border-radius: 0;
  font-size: 14px;
}

p.info img {
  width: 100px;
  vertical-align: top;
}

.source {
  position: relative;
  margin: 10px auto;
  width: 100%;
  max-width: 1000px;
  border: 1px solid #ccc;
  background: #f5f5f5;
  box-sizing: border-box;
  padding: 10px;
}

.source pre {
  font-size: 13px;
  text-align: left;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  word-break: break-all;
  white-space: pre-wrap;
}

.source span,
.source a {
  font-size: 13px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  font-weight: 300;
  background: #eee;
  padding: 0px 6px;
  font-weight: bold;
  text-decoration: underline;
}

.source pre .comment {
  color: #6a6;
}

.qrcode {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  background: #333;
}
.qrcode:before {
  position: absolute;
  content: "";
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  margin: 9px;
  top: 0;
  left: 0;
}
.qrviewer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.3);
  display: none;
}
.qrviewer img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  min-width: 300px;
  margin: auto;
  z-index: 10;
}

.play-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.2);
}
.play-container .play {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-sizing: border-box;
  background: url("~@/assets/images/360/play.svg") no-repeat;
  border: 0;
  background-size: 100% 100%;
}

header .home {
  margin: 0;
  font-size: 26px;
  background-repeat: no-repeat;
  width: 198px;
  height: 27px;
  position: absolute;
  text-align: left;
}

.home img.egjs_logo {
  width: 34px;
  display: block;
  margin-bottom: -8px;
}

.home a {
  line-height: 40px;
  letter-spacing: -0.8px;
  text-decoration: none;
  display: inline-block;
  color: #2b2b2b;
}
.fullscreen-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.enterfs {
  display: block !important;
}
.fullscreen-container .enterfs {
  display: none !important;
}
.exitfs {
  display: none !important;
}
.fullscreen-container .exitfs {
  display: block !important;
}
.entervr {
  display: block !important;
}

.panoviewer-control button {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 5px;
  border: none;
  margin: 5px;
  padding: 5px;
  outline: none;
  font-size: 0px;
  margin-bottom: 5px;
}
.panoviewer-control button.enterfs,
.panoviewer-control button.exitfs {
  margin-bottom: 10px;
}
.panoviewer-control button svg {
  width: 30px;
  height: 30px;
}

.panoviewer-control {
  text-align: center;
  position: absolute;
  font-weight: 300;
  top: 0px;
  right: 5px;
  z-index: 1;
}
.panoviewer-control .camera {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.panoviewer-control .pie,
.camera {
  height: 40px;
  width: 40px;
}

.image360_loading,
.image360_loading:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.image360_loading {
  display: none;
  width: 92px;
  height: 92px;
  z-index: 1;
}

.image360_loading .image360_loadingBar {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  width: 32px;
  height: 8px;
  margin: auto;
  background-image: url("~@/assets/images/360/common_loading_mo_white.gif");
  background-repeat: no-repeat;
  background-size: 32px 8px;
}

.image360_loading:before {
  background-image: url("~@/assets/images/360/sp_component.png");
  background-repeat: no-repeat;
  -webkit-background-size: 391px 349px;
  background-size: 391px 349px;
  display: inline-block;
  width: 92px;
  height: 92px;
  background-position: 0 -85px;
}

.image360_loading.is-loading {
  display: block;
}

.gyroTouchOptions {
  position: absolute;
  right: 0px;
  bottom: 0px;
  text-align: right;
}

.optionSet .option {
  border: 1px;
  border-radius: 3px;
  background-color: rgb(188, 188, 207);
  color: white;
  padding: 2px;
  height: 34px;
  box-sizing: border-box;
  font-size: 0;
  margin: 5px 0px;
  display: inline-block;
  vertical-align: middle;
}

.optionSet .option:hover {
  background-color: rgb(73, 73, 201);
}

.optionSet .option.selected {
  background-color: rgb(175, 64, 226);
}

.selectGroup {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.selectGroup .option {
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  line-height: 34px;
  padding: 0px 10px;
  min-width: 34px;
  text-align: center;
}

.statusButton.option {
  position: relative;
  background-color: #7fbeff;
  margin: 5px;
}
.selectGroup .option span {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  background-size: 100% 100%;
}
.selectGroup .option span.vertical {
  transform: rotate(90deg);
}
.statusButton.option svg {
  width: 40px;
  fill: #fff;
  stroke: #fff;
}

.statusButton.option:hover {
  background-color: #7f1e1f;
}

.selectGroup.hide {
  display: none;
}

@media screen and (max-width: 500px) {
  .panoviewer-control {
    right: 0px;
    top: 0px;
  }
  .panoviewer-control button {
    margin: 5px 5px 5px 0px;
    padding: 5px;
    float: right;
  }
  .panoviewer-control button svg {
    width: 20px;
    height: 20px;
  }
  .panoviewer-control .camera {
    transform: scale(0.7);
  }
}
.swal2-popup.swal2-toast {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
  padding: 0.625em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 0.625em #d9d9d9;
}
.swal2-popup.swal2-toast .swal2-header {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}
.swal2-popup.swal2-toast .swal2-title {
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin: 0 0.6em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  position: static;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}
.swal2-popup.swal2-toast .swal2-content {
  -webkit-box-pack: start;
  justify-content: flex-start;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1.8em;
  font-weight: 700;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: 0.25em;
  }
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="left"] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="right"] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  flex-basis: auto !important;
  width: auto;
  height: auto;
  margin: 0 0.3125em;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 0.3125em;
  padding: 0.3125em 0.625em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4);
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}
.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.8em;
  left: -0.5em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 2em 2em;
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.25em;
  left: 0.9375em;
  -webkit-transform-origin: 0 1.5em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="tip"] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="long"] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-tip {
  -webkit-animation: swal2-toast-animate-success-line-tip 0.75s;
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-long {
  -webkit-animation: swal2-toast-animate-success-line-long 0.75s;
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: swal2-toast-show 0.5s;
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: swal2-toast-hide 0.1s forwards;
  animation: swal2-toast-hide 0.1s forwards;
}
.swal2-container {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  -webkit-transition: background-color 0.1s;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show {
  background: rgba(0, 0, 0, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: 0 0 !important;
}
.swal2-container.swal2-top {
  -webkit-box-align: start;
  align-items: flex-start;
}
.swal2-container.swal2-top-left,
.swal2-container.swal2-top-start {
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.swal2-container.swal2-top-end,
.swal2-container.swal2-top-right {
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.swal2-container.swal2-center {
  -webkit-box-align: center;
  align-items: center;
}
.swal2-container.swal2-center-left,
.swal2-container.swal2-center-start {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.swal2-container.swal2-center-end,
.swal2-container.swal2-center-right {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom {
  -webkit-box-align: end;
  align-items: flex-end;
}
.swal2-container.swal2-bottom-left,
.swal2-container.swal2-bottom-start {
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.swal2-container.swal2-bottom-end,
.swal2-container.swal2-bottom-right {
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom-end > :first-child,
.swal2-container.swal2-bottom-left > :first-child,
.swal2-container.swal2-bottom-right > :first-child,
.swal2-container.swal2-bottom-start > :first-child,
.swal2-container.swal2-bottom > :first-child {
  margin-top: auto;
}
.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-flex: 1;
  flex: 1;
  align-self: stretch;
  -webkit-box-pack: center;
  justify-content: center;
}
.swal2-container.swal2-grow-row > .swal2-modal {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-flex: 1;
  flex: 1;
  align-content: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.swal2-container.swal2-grow-column {
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.swal2-container.swal2-grow-column.swal2-bottom,
.swal2-container.swal2-grow-column.swal2-center,
.swal2-container.swal2-grow-column.swal2-top {
  -webkit-box-align: center;
  align-items: center;
}
.swal2-container.swal2-grow-column.swal2-bottom-left,
.swal2-container.swal2-grow-column.swal2-bottom-start,
.swal2-container.swal2-grow-column.swal2-center-left,
.swal2-container.swal2-grow-column.swal2-center-start,
.swal2-container.swal2-grow-column.swal2-top-left,
.swal2-container.swal2-grow-column.swal2-top-start {
  -webkit-box-align: start;
  align-items: flex-start;
}
.swal2-container.swal2-grow-column.swal2-bottom-end,
.swal2-container.swal2-grow-column.swal2-bottom-right,
.swal2-container.swal2-grow-column.swal2-center-end,
.swal2-container.swal2-grow-column.swal2-center-right,
.swal2-container.swal2-grow-column.swal2-top-end,
.swal2-container.swal2-grow-column.swal2-top-right {
  -webkit-box-align: end;
  align-items: flex-end;
}
.swal2-container.swal2-grow-column > .swal2-modal {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-flex: 1;
  flex: 1;
  align-content: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(
    .swal2-top-left
  ):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(
    .swal2-center-left
  ):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(
    .swal2-bottom-end
  ):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)
  > .swal2-modal {
  margin: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: 0;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}
.swal2-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-actions {
  display: -webkit-box;
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.1)),
    to(rgba(0, 0, 0, 0.1))
  );
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.2)),
    to(rgba(0, 0, 0, 0.2))
  );
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  box-sizing: border-box;
  width: 2.5em;
  height: 2.5em;
  margin: 0.46875em;
  padding: 0;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border: 0.25em solid transparent;
  border-radius: 100%;
  border-color: transparent;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
  margin-right: 30px;
  margin-left: 30px;
}
.swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border: 3px solid #999;
  border-radius: 50%;
  border-right-color: transparent;
  box-shadow: 1px 1px 1px #fff;
}
.swal2-styled {
  margin: 0.3125em;
  padding: 0.625em 2em;
  box-shadow: none;
  font-weight: 500;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #3085d6;
  color: #fff;
  font-size: 1.0625em;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 1.0625em;
}
.swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4);
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}
.swal2-footer {
  -webkit-box-pack: center;
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}
.swal2-timer-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}
.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}
.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  -webkit-box-pack: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  overflow: hidden;
  -webkit-transition: color 0.1s ease-out;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 0;
  outline: initial;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
}
.swal2-close:hover {
  -webkit-transform: none;
  transform: none;
  background: 0 0;
  color: #f27474;
}
.swal2-close::-moz-focus-inner {
  border: 0;
}
.swal2-content {
  z-index: 1;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}
.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
  margin: 1em auto;
}
.swal2-file,
.swal2-input,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  color: inherit;
  font-size: 1.125em;
}
.swal2-file.swal2-inputerror,
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}
.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: 0 0 3px #c4e6f5;
}
.swal2-file::-webkit-input-placeholder,
.swal2-input::-webkit-input-placeholder,
.swal2-textarea::-webkit-input-placeholder {
  color: #ccc;
}
.swal2-file::-moz-placeholder,
.swal2-input::-moz-placeholder,
.swal2-textarea::-moz-placeholder {
  color: #ccc;
}
.swal2-file:-ms-input-placeholder,
.swal2-input:-ms-input-placeholder,
.swal2-textarea:-ms-input-placeholder {
  color: #ccc;
}
.swal2-file::-ms-input-placeholder,
.swal2-input::-ms-input-placeholder,
.swal2-textarea::-ms-input-placeholder {
  color: #ccc;
}
.swal2-file::placeholder,
.swal2-input::placeholder,
.swal2-textarea::placeholder {
  color: #ccc;
}
.swal2-range {
  margin: 1em auto;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}
.swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}
.swal2-input[type="number"] {
  max-width: 10em;
}
.swal2-file {
  background: inherit;
  font-size: 1.125em;
}
.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}
.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em;
}
.swal2-checkbox,
.swal2-radio {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-checkbox label,
.swal2-radio label {
  margin: 0 0.6em;
  font-size: 1.125em;
}
.swal2-checkbox input,
.swal2-radio input {
  margin: 0 0.4em;
}
.swal2-validation-message {
  display: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}
.swal2-icon {
  position: relative;
  box-sizing: content-box;
  -webkit-box-pack: center;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon 0.5s;
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark 0.5s;
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}
.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}
.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}
.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}
.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.4375em;
  left: -2.0635em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 3.75em 3.75em;
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.6875em;
  left: 1.875em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 3.75em;
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip 0.75s;
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long 0.75s;
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-progress-steps {
  -webkit-box-align: center;
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #3085d6;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #3085d6;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #3085d6;
}
[class^="swal2"] {
  -webkit-tap-highlight-color: transparent;
}
.swal2-show {
  -webkit-animation: swal2-show 0.3s;
  animation: swal2-show 0.3s;
}
.swal2-hide {
  -webkit-animation: swal2-hide 0.15s forwards;
  animation: swal2-hide 0.15s forwards;
}
.swal2-noanimation {
  -webkit-transition: none;
  transition: none;
}
.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}
@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid rgba(50, 100, 150, 0.4);
  }
}
@-webkit-keyframes swal2-toast-show {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
  }
}
@keyframes swal2-toast-show {
  0% {
    -webkit-transform: translateY(-0.625em) rotateZ(2deg);
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    -webkit-transform: translateY(0.3125em) rotateZ(2deg);
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
  }
}
@-webkit-keyframes swal2-toast-hide {
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-hide {
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@-webkit-keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@-webkit-keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@-webkit-keyframes swal2-show {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes swal2-show {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-hide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes swal2-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes swal2-rotate-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swal2-rotate-loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: transparent !important;
}
body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-top-left,
body.swal2-no-backdrop .swal2-container.swal2-top-start {
  top: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-top-end,
body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-left,
body.swal2-no-backdrop .swal2-container.swal2-center-start {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-end,
body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-left,
body.swal2-no-backdrop .swal2-container.swal2-bottom-start {
  bottom: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-end,
body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  right: 0;
  bottom: 0;
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)
    > [aria-hidden="true"] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)
    .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  background-color: transparent;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-left,
body.swal2-toast-shown .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-left,
body.swal2-toast-shown .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end,
body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-left,
body.swal2-toast-shown .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end,
body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}
body.swal2-toast-column .swal2-toast {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
}
body.swal2-toast-column .swal2-toast .swal2-actions {
  -webkit-box-flex: 1;
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: 0.3125em;
}
body.swal2-toast-column .swal2-toast .swal2-loading {
  -webkit-box-pack: center;
  justify-content: center;
}
body.swal2-toast-column .swal2-toast .swal2-input {
  height: 2em;
  margin: 0.3125em auto;
  font-size: 1em;
}
body.swal2-toast-column .swal2-toast .swal2-validation-message {
  font-size: 1em;
}
.move-exp-message {
  width: 100%;
  position: absolute;
  bottom: 0;

  background: rgba(0, 0, 0, 0.7);
}
</style>
